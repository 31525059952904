import React from 'react'

import '../style.scss'
import  '../../apis/youtube'
import Buttons from '../../header/Buttons.jsx'
import { VideoPlayerMid } from './VideoPlayerMid.jsx';




function VideoListMid({data}){
   
    return(

        <div className="VideoList">
            <Buttons/>
            <div className='videos_container'>
           <VideoPlayerMid data={data}/>
           </div>
           
        </div>
        
    );
}

 export default VideoListMid;