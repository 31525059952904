import React, { useEffect } from 'react'
import '../style.scss'
import  '../../apis/youtube'
import '../script.js'
import { useSelector,useDispatch } from 'react-redux';
import {Link, useParams, useSearchParams, useLocation} from 'react-router-dom';
import {videolink} from '../../actions'




function Video(videoData){
  let myArrayIDs
  let myArray

  const {adindex}=useParams()
  const [searchParams] = useSearchParams();
  const adindex2 = searchParams.get('ad');
  const skip = searchParams.get('s');
  const params=useLocation()
  
  const dispatch=useDispatch()
  
  const handlePlay=()=>{
 
    var userAgent = window.navigator.userAgent;

    if (/iPhone|iPad|iPod/.test(userAgent) && !window.MSStream) {
      
      document.getElementById("ad").pause()
  
      
    } 
    else{
      document.getElementById("ad").pause()
    
    }
    
  }


  useEffect(()=>{

    myArrayIDs = sessionStorage.getItem('ids');
    myArray = JSON.parse(myArrayIDs);

  if(myArrayIDs==null || myArray==null){
    let ids=[]
    myArrayIDs = JSON.stringify(ids);
    sessionStorage.setItem('ids',myArrayIDs)
    myArray=JSON.parse(myArrayIDs);
  }
  
    myArray.forEach((item,index)=>{
      document.getElementById(item).style.opacity=0.3
      document.getElementById(item).style.pointerEvents="none"
   
    })
  

    const myArraynoIds = JSON.stringify(myArray);
  
    sessionStorage.setItem('ids',myArraynoIds)
    
  
    },[])


  return videoData.map(({snippet,id},index)=>{
    var rand=Math.floor(Math.random()*10000000);


    
    return(
        <div className="VideoPlayer" key={index} id={id.videoId}>
         
         <Link  to={`video/${index}${params.search}`}> {<img className="Iframe" src={snippet.thumbnails.medium.url}></img>} </Link>


         <div className='VideoPlayerDescr-Container'>
          <div className="Circl">
           <img className='Circl' src={snippet.thumbnails.medium.url} alt=""/>
          </div>
          <div className="TitleContainer">
          <div className="VideoName">{snippet.title}</div>
          <div className="ViewandChannel">{snippet.channelTitle}</div>
          <div className="ViewandChannel">{rand} Aufrufe</div>
          </div>
          </div>
        </div>
    )
  });
  
}


var paramsString = document.location.search; 
var searchParams = new URLSearchParams(paramsString);
    



export const VideoPlayerMid = ({ data }) => {

  return <>{Video(data)}</>;
};




