import React,{useEffect, useState} from 'react';

import './App.scss';

import Main from './body/Main.jsx';
import MainBan from './banner/Main.jsx';
import MainMid from './body/mid/MainMid.jsx';
import MainAfter from './body/after/MainAfter.jsx';

import {Routes,Route, useParams, useSearchParams} from "react-router-dom";
import SelectedVideo from './SelectedVideo';
import SelectedVideoBanner from './SelectedVideoBanner';
import SelectedVideoMid from './SelectedVideoMid';
import SelectedVideoAfter from './SelectedVideoAfter';
import Error from './Error';
import data from './apis/data.json'
import dataBanner from './banner/data/data.json'
import Impressum from './body/Impressum.jsx';
import Start from './body/Start.jsx';

import { Layout } from './component/Layout';






export function AddLibrary() {
  const script = document.createElement('script')
    script.src="https://kunde.trend-research.de/multimedia/plugin/2021_adcheck.js"
    script.async=true
    document.body.appendChild(script)
}


function App() {
  let videoList=[]
  const [videos,setVideos]=useState([])
  const [err,setErr]=useState("")

/* 
  if(!sessionStorage.getItem('bannerState')){
    sessionStorage.setItem('bannerState', true)
  }
*/


  const handleGetDatafromUrl=()=>{
    var paramsString=document.location.search;
    var searchParams=new URLSearchParams(paramsString);

   //window.localStorage.setItem('name',searchParams.get("name"))
   //window.localStorage.setItem('type',searchParams.get("type"))
   //window.localStorage.setItem('skipp',searchParams.get("s"))
   //window.localStorage.setItem('thema',searchParams.get("thema"))
   //window.localStorage.setItem('index',searchParams.get("index"))
   
   
  }
   

  

  useEffect(()=>{

   

    setVideos(data.items)
    videoList.push(data.items)
 
    //handleGetDatafromUrl()
   
    
    document.title = "Youtcheck"; 
 

    const script = document.createElement('script')
    script.src="https://kunde.trend-research.de/multimedia/plugin/2021_adcheck.js"
    script.defer=false
    document.body.appendChild(script)

   
  


    return()=>{
      document.body.removeChild(script)
    }
    
    

  },[])

  
 




 

   
  return (

    
  
   
    
    <div>






<Routes>

     
    
     


    

    <Route path="/" element={ <Start/>}/>

    <Route path="/youtcheck/demo" element={ <Impressum/>}/>

  
   
    <Route path="/youtcheck/banner" element={<Layout/>}>
    <Route index element={<MainBan data={dataBanner.items}/>}/> 
    <Route path='youtcheckbannervideo' element={<SelectedVideoBanner videolist={videos}/>}/> 
    </Route>


    <Route path="/youtcheck/pre/:adindex" element={<Layout/>}> 

   

     <Route index element={<Main data={videos}/>}/>

      {/*  <Route path={`/${document.location.pathname.replace(/\//g, "")}`}>*/}
     <Route path="video/" element={<SelectedVideo videolist={videos}/>}/>
      

      </Route>



      <Route path="/youtcheck/mid" element={<Layout/>}> 

     <Route index element={<MainMid data={videos}/>}/>
    
   

      
   
      <Route path="video/:vidindex" element={<SelectedVideoMid videolist={videos}/>}/>
      
    

    

      </Route>


      <Route path="/youtcheck/after" element={<Layout/>}> 

    <Route index element={<MainAfter data={videos}/>}/>



 

    <Route path="/youtcheck/after/view" element={<SelectedVideoAfter videolist={videos}/>}/>
 




    </Route>
     
<Route path="/error" element={<Error errr={err}/>}/>


{/*<Route path="/youtcheck/admin" element={<Admin/>}/>*/}
    
   
 
      


     </Routes>


{/*<input type="text" value={this.state.userInput} onChange={this.onTest}/>*/}

{/*{AddLibrary()}*/}
</div>

   
  
  );

  
  
}

export default App;